import {
  collection,
  getDocs,
  getFirestore,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  Text,
  Button,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Image,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import VideoContainer from "../components/VideoPlayer"; // Import VideoContainer

enum AdminOptions {
  LatestVideos = "Latest Videos",
  LatestImages = "Latest Images",
  LatestProjectImages = "Latest Proj Img",
  Projects = "Projects",
  Cancel = "Cancel",
}

const Admin = () => {
  const [projects, setProjects] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [images, setImages] = useState([]);

  const fetchProjects = useCallback(async () => {
    setIsFetching(true);
    const db = getFirestore();
    const projectsRef = collection(db, "projects"); // Changed from "images" to "projects"
    try {
      const querySnapshot = await getDocs(projectsRef);
      const fetchedProjects = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name, // Assuming each project document has a "name" field
      }));

      setProjects(fetchedProjects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const fetchImages = useCallback(async () => {
    const db = getFirestore();
    const storage = getStorage();
    let collectionRef;
    let queryRef;

    if (selectedOption === AdminOptions.LatestImages) {
      collectionRef = collection(db, "images");
      queryRef = query(collectionRef, orderBy("generation", "desc"), limit(50));
    } else if (selectedOption === AdminOptions.LatestProjectImages) {
      collectionRef = collection(db, "latest");
      queryRef = query(collectionRef, orderBy("updatedAt", "desc"), limit(50));
    } else if (selectedOption === AdminOptions.LatestVideos) {
      console.log("Fetching latest videos");
      collectionRef = collection(db, "videos");
      queryRef = query(collectionRef, orderBy("generation", "desc"), limit(50));
    }

    try {
      const querySnapshot = await getDocs(queryRef);

      const mediaPromises = querySnapshot.docs.map(async (doc) => {
        const data = doc.data();
        console.log("data", data);
        let url;
        if (selectedOption === AdminOptions.LatestVideos) {
          if (!data.path) {
            console.log("Skipping video without path:", doc.id);
            return null; // Skip this video
          }
          const videoRef = ref(storage, data.path);
          console.log("videoRef", videoRef);
          try {
            url = await getDownloadURL(videoRef);
          } catch (error) {
            console.error("Error getting download URL:", error);
            console.log("data", data);
            return null; // Skip this video if there's an error
          }
        } else if (selectedOption === AdminOptions.LatestProjectImages) {
          const mediaRef = ref(storage, data.mediaUrl);
          url = await getDownloadURL(mediaRef);
        } else {
          if (!data.filename) {
            console.error("Filename is undefined for document ID:", doc.id);
            return null; // Skip this document or handle as needed
          }
          const pathReference = ref(
            storage,
            `${data.userId}/${data.projectId}/images/${data.filename}`
          );
          url = await getDownloadURL(pathReference);
        }
        return {
          id: doc.id,
          url,
          ...data,
        };
      });
      const fetchedMediaRaw = await Promise.all(mediaPromises.filter(Boolean));
      const fetchedMedia = fetchedMediaRaw.filter(Boolean);
      console.log("fetchedMedia", fetchedMedia);
      setImages(fetchedMedia);
    } catch (error) {
      console.error("Error fetching media:", error);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (
      selectedOption === AdminOptions.LatestImages ||
      selectedOption === AdminOptions.LatestProjectImages ||
      selectedOption === AdminOptions.LatestVideos
    ) {
      fetchImages();
    }
  }, [selectedOption, fetchImages]);

  const { showActionSheetWithOptions } = useActionSheet();

  useEffect(() => {
    if (showActionSheet) {
      const options = [
        AdminOptions.LatestVideos,
        AdminOptions.LatestImages,
        AdminOptions.LatestProjectImages,
        AdminOptions.Projects,
        AdminOptions.Cancel,
      ];
      const cancelButtonIndex = 4;
      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => {
          if (buttonIndex !== cancelButtonIndex) {
            setSelectedOption(options[buttonIndex]);
          }
          setShowActionSheet(false);
        }
      );
    }
  }, [showActionSheet, showActionSheetWithOptions]);

  const renderContent = () => {
    switch (selectedOption) {
      case AdminOptions.LatestVideos:
        return (
          <ScrollView>
            {images.map((video, index) => (
              <View key={index} style={styles.videoContainer}>
                <Text style={styles.videoInfo}>
                  User: {video.userId} - Project: {video.projectId} - Date:{" "}
                  {new Date(video.generation / 1000).toLocaleDateString()}
                  URL: {video.url}
                </Text>
                <VideoContainer videoPath={video.url} />
              </View>
            ))}
          </ScrollView>
        );
      case AdminOptions.LatestImages:
      case AdminOptions.LatestProjectImages:
        return (
          <FlatList
            data={images}
            renderItem={({ item }) => (
              <View style={styles.imageContainer}>
                <Text style={styles.imageInfo}>
                  User: {item.userId} - Project: {item.projectId} - Date:{" "}
                  {selectedOption === AdminOptions.LatestImages
                    ? new Date(item.generation / 1000).toLocaleDateString()
                    : item.updatedAt?.toDate().toLocaleDateString()}
                </Text>
                <Image
                  source={{ uri: item.url }}
                  style={styles.image}
                  resizeMode="contain"
                />
              </View>
            )}
            keyExtractor={(item) => item.id}
            style={{ flexDirection: "column" }}
          />
        );
      case AdminOptions.Projects:
        return (
          <ScrollView>
            {isFetching ? (
              <Text>Loading...</Text>
            ) : (
              projects.map((project) => (
                <Text key={project.id}>
                  {project.id} - {project.name}
                </Text>
              ))
            )}
          </ScrollView>
        );
      default:
        return null;
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.topBar}>
        <TouchableOpacity onPress={() => setShowActionSheet(true)}>
          <Text style={styles.topBarText}>
            {selectedOption || "Select Option"}
          </Text>
          <MaterialCommunityIcons name="chevron-down" size={24} color="black" />
        </TouchableOpacity>
      </View>
      {renderContent()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  text: {
    marginBottom: 20,
  },
  topBar: {
    backgroundColor: "#e0e0e0",
    padding: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topBarText: {
    fontSize: 16,
    color: "black",
  },
  imageContainer: {
    marginBottom: 20, // Adds gap between images
  },
  imageInfo: {
    fontSize: 14,
    color: "grey",
    marginBottom: 5, // Space between text and image
  },
  image: {
    width: 600, // Set image width
    height: undefined, // Maintain aspect ratio
    aspectRatio: 1, // Default aspect ratio, adjust as needed
  },
  videoContainer: {
    marginBottom: 20, // Adds gap between videos
  },
  videoInfo: {
    fontSize: 14,
    color: "grey",
    marginBottom: 5, // Space between text and video
  },
});

export default Admin;
